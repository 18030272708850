import nuxtStorage from 'nuxt-storage';
import hosts from '../data/hosts.json'
import { defineStore } from 'pinia'

export const languagesDefault = [
      { flag: 'es', language: 'es', title: 'Español' },
      { flag: 'en', language: 'en', title: 'English' },
      { flag: 'ca', language: 'ca', title: 'Catalá' },
      { flag: 'eu', language: 'eu', title: 'Euskera' },
      { flag: 'fr', language: 'fr', title: 'French' },
      { flag: 'de', language: 'de', title: 'Deutsch' },
      { flag: 'nl', language: 'nl', title: 'Dutch' },
      { flag: 'pl', language: 'pl', title: 'Polish' },
      { flag: 'no', language: 'no', title: 'Norwegian' },
      { flag: 'ro', language: 'ro', title: 'Romanian' }
    ]
    
export const useStore = defineStore('store', {
  state: () => ({
    api_url: "",
    host: "",
    TITULO: "",
    NOTARIA: null,
    VIEWMODEL: {},
    ModoAccesible: false,
    token: null,
    user:null,
    idioma: "es",
    languages: languagesDefault,
    css_list: null,
    RecaptchaGroups: [null, '6LcoRlklAAAAAE3SgHyos1jSBhRG-NvwKESOy3MT', '6Ld6r1QpAAAAAKECU6jo-xhLRHsnu_2ofmOS5v4W', '6Lep4lQpAAAAAP-mTLTamzilYsfiByuPs-Q1GsAj', '6Lcg41QpAAAAAMQ2wDYA9ryclG3RIYLcXHG6lu63'],
    recaptchaSiteKey: null
  }),
  actions: {
    async initNotaria(headers: any) {
      var id=1;
      try{
        //const route = useRoute()
        if (headers && headers.host) {
          console.log(new Date())
          console.log("initNotaria ");
          // console.log('Environment')
          // console.log(process.env.NODE_ENV)
          console.log('headers')
          // console.log(process.env.NUXT_ENV_API_URL)

          this.host = headers.host
          //console.log(JSON.stringify(headers))
          // debugger
          if (process.env.NODE_ENV === 'development') {
            this.api_url = process.env.NUXT_ENV_API_URL
          } else {
            this.api_url = 'https://' + this.host + '/'
          }

          console.log('backend: ' + this.api_url)
        }



        
        hosts.map((h) => {
          if (h.hosts.includes(this.host)) {
            id = h.id;
          }
        });

        console.log('idNotaria: ' + id)
        //Obtener JSON Notaria
        //await this.obtenerNotaria(id);

        var res;
        var idioma =
          this.idioma && this.idioma != "es" ? "_" + this.idioma : "";
        var dataurl = ""
        if (process.env.NODE_ENV === "development") {
          ////console.log(api_url + 'appdata/' + id +'/data/notaria.json')
          dataurl = this.api_url +
            "appdata/" +
            id +
            "/data/notaria" +
            idioma +
            ".json?v=" +
            Date.now()
        } else {
          dataurl = this.api_url + "appdata/data/notaria" + idioma + ".json?v=" + Date.now()
        }
        console.log('Fetch: ' + dataurl)
        //debugger
        if (this.NOTARIA) {
          if (this.NOTARIA.Languaje != this.idioma) {
            res = await $fetch(dataurl)
          }
        } else {
          res = await $fetch(dataurl)
        }

        if (res) {
          //console.log(res)
          this.NOTARIA = res;
          // console.log("NOTARIA")
          // console.log(state.NOTARIA)
          this.TITULO = this.NOTARIA.Nombre;
          if (this.NOTARIA.Personalizar.RecaptchaGroup) {
            this.recaptchaSiteKey = this.RecaptchaGroups[this.NOTARIA.Personalizar.RecaptchaGroup]
          }
        }
        //console.log(this.NOTARIA)

        //////

        await this.loadcssjs(id);

        await this.initLogin();

        //Se configura en el panel de control
        // await dispatch('getReviews')
      }catch(e){

      }
      
      return id
    },

    async FecthModoAccesible() {
      var r = eval(typeof window !== 'undefined' ? nuxtStorage.localStorage.getData("ModoAccesible"):false);
      this.ModoAccesible = r;
    },
    async toggleModoAccesible() {
      nuxtStorage.localStorage.setData("ModoAccesible", !this.ModoAccesible);
      this.ModoAccesible = !this.ModoAccesible;
    },
    async setIdioma(v: string) {
      this.idioma = v;
      //await this.initNotaria(null);
    },
    async initLogin() {
      this.token = typeof window !== 'undefined' ? nuxtStorage.localStorage.getData("token"):null;
    },

    getReviews() {
      console.log("Entra en getReviews");

      const url = `https://maps.googleapis.com/maps/api/place/details/json?placeid=ChIJw4xGRQiBYw0Rj66sxkqopA0&key=AIzaSyCzggys0LtiRm_d97j6ud0m2l6khuYzqq4`;

      useFetch(url)
        .then((response) => {
          // Procesa la respuesta para obtener las reseñas
          const reviews = response.data.result.reviews;
          console.log(reviews);
        })
        .catch((error) => {
          console.error("Error al obtener las reseñas:", error);
          console.log(error.response.data.error);
        });
    },
    async loadcssjs(id: any) {
      var timestamp = new Date().getTime();
      //inyectar css
      var url_css_dev = this.api_url + "appdata/" + id + "/static/";
      var url_css_prod = this.api_url + "appdata/static/";
      var url_css =
        process.env.NODE_ENV === "development" ? url_css_dev : url_css_prod;
      var css = [
        "header.css",
        //"home-banner.css",
        // "home-blog.css",
        // "home-pn.css",
        //"home-servicios.css",
        "home-testimonios.css",
        "blog.css",
        // "servicios.css",
        //"form.css",
        "footer.css",
        "notarios.css",
        // "team.css",
        // "contacto.css",
        "gallery.css",
        "home-gallery.css"
      ];
      var csslist: Array<string> = []
      if (this.NOTARIA.Personalizar.CustomCSS !== "") css.push("custom.css");


      css.forEach(c => {
        csslist.push(url_css + c + "?v=" + timestamp)
      })
      this.css_list = csslist

      // css.forEach((c) => {
      //   let file = document.createElement("link");
      //   file.rel = "stylesheet";
      //   file.href = url_css + c + "?v=" + timestamp;
      //   document.head.appendChild(file);
      // });

      // if (state.NOTARIA.Personalizar.CustomJS !== "") {
      //   var jsonScript = state.NOTARIA.Personalizar.CustomJS;
      //   let sc = document.createElement("script");
      //   sc.type = "text/javascript";
      //   sc.innerText = "function initImportedScript(){" + jsonScript + "}";
      //   document.head.appendChild(sc);
      //   //
      //   initImportedScript();
      // }
    },
    async doLogin(userLogin: any) {
      var loginUrl=""
      if (process.env.NODE_ENV === 'development'){
        loginUrl = this.api_url + 'api/authentication'
      }else{
        loginUrl = 'https://controlpanel.notin.es/api/authentication'
      }

      var result = await $fetch(loginUrl,{method:"POST",body:userLogin, headers: { 'Content-Type': 'application/json' } })

      //console.log(result)
      if (result) {
        debugger
        this.token=result;
        var tokenparsed = this.parseJwt(result)
        
        this.user = tokenparsed.user
        //commit("setUserNotarias", userNotarias);
        //axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        //await Preferences.set({ key: 'user', value: JSON.stringify(user) });
        nuxtStorage.localStorage.setData('token',  this.token);
        nuxtStorage.localStorage.setData('user', tokenparsed.user );
        //await Preferences.set({ key: 'userNotarias', value:  userNotarias });
        ////console.log(token)
        var user = JSON.parse(tokenparsed.user)
        //console.log(user)
        //console.log(state.NOTARIA.id)
        if (user.Notarias.includes(this.NOTARIA.id))
          return true;
        else
          return false;
      } else {
        return false;
      }
    },
    async doLogOut() {
      //console.log("doLogOut");
      this.token=null;
      this.user=null;
      nuxtStorage.localStorage.setData('token',  null);
      nuxtStorage.localStorage.setData('user', null );
      //axios.defaults.headers.common["Authorization"] = "";
    },
    parseJwt(token: string) {
      if (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        return JSON.parse(jsonPayload);
      } else {
        alert('Falló la autenticación, necesita un usuario');
      }
    }
  }
})