import { default as _404mzmT5HxZC7Meta } from "/notarias_webs_nuxt/pages/404.vue?macro=true";
import { default as AccesibilidadUwjYXCfuP7Meta } from "/notarias_webs_nuxt/pages/Accesibilidad.vue?macro=true";
import { default as AvisoLegalDbf05ngksBMeta } from "/notarias_webs_nuxt/pages/AvisoLegal.vue?macro=true";
import { default as BlogRWwQ9FVkNDMeta } from "/notarias_webs_nuxt/pages/Blog.vue?macro=true";
import { default as _91id_93ud5OFd0rMWMeta } from "/notarias_webs_nuxt/pages/blog/[id].vue?macro=true";
import { default as BlogDetailsBMhQD5wastMeta } from "/notarias_webs_nuxt/pages/blog/BlogDetails.vue?macro=true";
import { default as ContactoTrSeyZtGYDMeta } from "/notarias_webs_nuxt/pages/Contacto.vue?macro=true";
import { default as Equipox4wi3YGnBGMeta } from "/notarias_webs_nuxt/pages/Equipo.vue?macro=true";
import { default as error92HC4WLc22Meta } from "/notarias_webs_nuxt/pages/error.vue?macro=true";
import { default as GalleryTTjVFsxpxOMeta } from "/notarias_webs_nuxt/pages/Gallery.vue?macro=true";
import { default as index2FDhv5rwNKMeta } from "/notarias_webs_nuxt/pages/index.vue?macro=true";
import { default as Notariosc0Id7jzHkeMeta } from "/notarias_webs_nuxt/pages/Notarios.vue?macro=true";
import { default as PoliticaCookiesoAt18KDasEMeta } from "/notarias_webs_nuxt/pages/PoliticaCookies.vue?macro=true";
import { default as ProteccionDatosTC2pJ6btVGMeta } from "/notarias_webs_nuxt/pages/ProteccionDatos.vue?macro=true";
import { default as ServiciosdldPqgdQvrMeta } from "/notarias_webs_nuxt/pages/Servicios.vue?macro=true";
import { default as _91id_93WUdbRNK3EjMeta } from "/notarias_webs_nuxt/pages/servicios/[id].vue?macro=true";
import { default as ServicemgDkKXbl3OMeta } from "/notarias_webs_nuxt/pages/servicios/Service.vue?macro=true";
import { default as ServiceDetailsUTfzqROyEOMeta } from "/notarias_webs_nuxt/pages/servicios/ServiceDetails.vue?macro=true";
import { default as UnderConstructionNqdclkKopeMeta } from "/notarias_webs_nuxt/pages/UnderConstruction.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    component: () => import("/notarias_webs_nuxt/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "Accesibilidad",
    path: "/Accesibilidad",
    component: () => import("/notarias_webs_nuxt/pages/Accesibilidad.vue").then(m => m.default || m)
  },
  {
    name: "AvisoLegal",
    path: "/AvisoLegal",
    component: () => import("/notarias_webs_nuxt/pages/AvisoLegal.vue").then(m => m.default || m)
  },
  {
    name: "Blog",
    path: "/Blog",
    component: () => import("/notarias_webs_nuxt/pages/Blog.vue").then(m => m.default || m)
  },
  {
    name: "blog-id",
    path: "/blog/:id()",
    component: () => import("/notarias_webs_nuxt/pages/blog/[id].vue").then(m => m.default || m)
  },
  {
    name: "blog-BlogDetails",
    path: "/blog/BlogDetails",
    component: () => import("/notarias_webs_nuxt/pages/blog/BlogDetails.vue").then(m => m.default || m)
  },
  {
    name: "Contacto",
    path: "/Contacto",
    component: () => import("/notarias_webs_nuxt/pages/Contacto.vue").then(m => m.default || m)
  },
  {
    name: "Equipo",
    path: "/Equipo",
    component: () => import("/notarias_webs_nuxt/pages/Equipo.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/notarias_webs_nuxt/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "Gallery",
    path: "/Gallery",
    component: () => import("/notarias_webs_nuxt/pages/Gallery.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "Notarios",
    path: "/Notarios",
    component: () => import("/notarias_webs_nuxt/pages/Notarios.vue").then(m => m.default || m)
  },
  {
    name: "PoliticaCookies",
    path: "/PoliticaCookies",
    component: () => import("/notarias_webs_nuxt/pages/PoliticaCookies.vue").then(m => m.default || m)
  },
  {
    name: "ProteccionDatos",
    path: "/ProteccionDatos",
    component: () => import("/notarias_webs_nuxt/pages/ProteccionDatos.vue").then(m => m.default || m)
  },
  {
    name: "Servicios",
    path: "/Servicios",
    component: () => import("/notarias_webs_nuxt/pages/Servicios.vue").then(m => m.default || m)
  },
  {
    name: "servicios-id",
    path: "/servicios/:id()",
    component: () => import("/notarias_webs_nuxt/pages/servicios/[id].vue").then(m => m.default || m)
  },
  {
    name: "servicios-Service",
    path: "/servicios/Service",
    component: () => import("/notarias_webs_nuxt/pages/servicios/Service.vue").then(m => m.default || m)
  },
  {
    name: "servicios-ServiceDetails",
    path: "/servicios/ServiceDetails",
    component: () => import("/notarias_webs_nuxt/pages/servicios/ServiceDetails.vue").then(m => m.default || m)
  },
  {
    name: "UnderConstruction",
    path: "/UnderConstruction",
    meta: UnderConstructionNqdclkKopeMeta || {},
    component: () => import("/notarias_webs_nuxt/pages/UnderConstruction.vue").then(m => m.default || m)
  },
  {
    name: "en",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ca",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "eu",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "de",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "fr",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "nl",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "pl",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "no",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "ro",
    path: "/:catchall(.*)",
    component: () => import("/notarias_webs_nuxt/pages/index.vue").then(m => m.default || m)
  }
]